import { useEffect, useState } from "react";
import axios from "axios";
import { getEventTypes } from "../../utils/apiUtils";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import { Button } from "@mui/material";



export default function Compare() {
  const url = 'https://dev.pmwevents.com';
  // const url = 'http://localhost:4100';
  const [eventTypes, setEventTypes] = useState([]);
  const [eventType, setEventType] = useState('');
  const [typeId, setTypeId] = useState('');
  const [notCompare, setNotCompare] = useState(false);
  const [compareEvents, setCompareEvents] = useState([]);
  const [message, setMessage] = useState('');
  const [comparison, setComparison] = useState(false);
  const [analytics, setAnalytics] = useState([]);
  const [eventOne, setEventOne] = useState(0);
  const [eventTwo, setEventTwo] = useState(0);
  const [eventsToCompare, setEventsToCompare] = useState([])

  useEffect(() => {
    getEventTypes()
      .then((data) => {
        setEventTypes(data)
      }).catch((err) => {
        console.log('Could not get event types')
      })
  },[eventTypes])

  const handleEventSelect = (type) => {
    setTypeId(parseInt(type))
    setComparison(false)
    setAnalytics([])
    for (let e = 0; e < eventTypes.length; e++) {
      if (eventTypes[e].eventType === type) {
        setEventType(eventTypes[e].name)
      }
    }
    let event = {
      type: parseInt(type)
    }
    axios.post(`${url}/events/get-event-type`, event)
      .then((res) => {
        if (res.data.length < 2) {
          setMessage('This event has not run enough to be comparable')
          setNotCompare(true)
          setTimeout(() => {
            setNotCompare(false)
          }, 2000)
        } else {
          setCompareEvents(res.data)
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  const handleEventOne = (id) => {
    console.log(id)
    setComparison(false);
    setEventOne(id);
    setEventsToCompare([])
    let localArray = compareEvents

    for (let e = 0; e < localArray.length; e++) {
      if (id == localArray[e].eventId) {
        localArray.splice(e, 1)
      }
    }
    setEventsToCompare(localArray)
  }

  const handleEventTwo = (id) => {
    setEventTwo(id)
  }

  const handleCompare = () => {
    if (eventOne === 0 || eventTwo === 0) {
      setMessage('Please select 2 events')
      setNotCompare(true)
      setTimeout(() => {
        setNotCompare(false)
      }, 2000)
    } else if (eventOne == eventTwo){
      setMessage('Both events selected are the same')
      setNotCompare(true)
      setTimeout(() => {
        setNotCompare(false)
      }, 2000)
    }else {
      let events = [eventOne, eventTwo]
      axios.post(`${url}/events/compare-events`, events)
        .then((res) => {
          console.log(res.data)
          setAnalytics(res.data)
          setComparison(true)
        }).catch((err) => {
          console.log(err)
        })
    }
  }


  return (
    <main className="dash">
      <section className="dash__main">
        <h1>Compare Events</h1>
        <FormControl className='form--select'>
          <InputLabel id="select-label">Select an Event Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={typeId}
            label="Age"
            onChange={(e) => handleEventSelect(e.target.value)}
            sx={{ backgroundColor: '#fff' }}
          >
            {eventTypes.map((event) => {
              return (
                <MenuItem key={event.eventType} value={event.eventType}>{event.name}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
        {notCompare ? (
          <Alert severity="warning" sx={{ marginTop: '2%', with: "80%" }} variant="filled">
            {message}
          </Alert>
        ) : null}

        {compareEvents.length > 0 ? (
          <>
            <h2>Comparing {eventType} events</h2>
            <div className="comp__row">
              <FormControl className='form--comp' size="small">
                <InputLabel id="select-label">Select Event</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={eventOne}
                  label="Age"
                  onChange={(e) => handleEventOne(e.target.value)}
                  sx={{ backgroundColor: '#fff' }}
                >
                  {compareEvents.map((event) => {
                    return (
                      <MenuItem key={event.eventId} value={event.eventId}>{new Date(event.eventDate).toLocaleDateString('en-GB')}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              {/* SHOWS WHEN FIRST EVENT HAS BEEN SELECTED, ARRAY WILL NOT INCLUDE FIRST EVENT */}
              {eventsToCompare.length > 0 ? (
                <FormControl className='form--comp' size="small" >
                  <InputLabel id="select-label">Select Event</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={eventTwo}
                    label="Age"
                    onChange={(e) => handleEventTwo(e.target.value)}
                    sx={{ backgroundColor: '#fff' }}
                  >
                    {eventsToCompare.map((event) => {
                      return (
                        <MenuItem key={event.eventId} value={event.eventId}>{new Date(event.eventDate).toLocaleDateString('en-GB')}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>

              ) : null}
            </div>
          </>
        ) : null}
        {!comparison ? (
          <Button variant='contained' color='primary' sx={{ marginTop: "2%" }} onClick={handleCompare}>Compare</Button>
        ) :
          <section className="event__comparison">
            {analytics.map((event, i) => {
              let salesDif = 0;
              let salesPer = 0;
              let webDif = 0;
              let webPer = 0;
              let adminDif = 0;
              let adminPer = 0;
              let revDif = 0;
              let revPer = 0;
              let salesColor, webColor, adminColor, revColor;
              if (i === 1) {
                salesDif = event.totalSales - analytics[0].totalSales
                salesPer = ((event.totalSales - analytics[0].totalSales) * 100 / analytics[0].totalSales).toFixed(2)
                if (salesDif < 0) {
                  salesColor = '#E8160C'
                } else if (salesDif > 0) {
                  salesColor = '#038C3E'
                }
                webDif = event.web - analytics[0].web
                webPer = ((event.web - analytics[0].web) * 100 / analytics[0].web).toFixed(2)
                if (webDif < 0) {
                  webColor = '#E8160C'
                } else if (webDif > 0) {
                  webColor = '#038C3E'
                }
                adminDif = event.admin - analytics[0].admin
                adminPer = ((event.admin - analytics[0].admin) * 100 / analytics[0].admin).toFixed(2)
                if (adminDif < 0) {
                  adminColor = '#E8160C'
                } else if (adminDif > 0) {
                  adminColor = '#038C3E'
                }
                revDif = event.revenue - analytics[0].revenue
                revPer = ((event.revenue - analytics[0].revenue) * 100 / analytics[0].revenue).toFixed(2)
                if (revDif < 0) {
                  revColor = '#E8160C'
                } else if (revDif > 0) {
                  revColor = '#038C3E'
                }
              }
              return (
                <section className="compare__event">
                  <div className="card">
                    <h3>Total Sales: {event.totalSales} {i === 1 ? <span style={{ color: salesColor }}>{salesDif}</span> : null}</h3>
                    {i === 1 ? <h3 style={{margin: '0 auto'}}><span style={{ color: salesColor }}>{salesPer}%</span></h3> : null}
                  </div>
                  <div className="eventComp__row">
                    <div className="comp--card">
                      <h6>Web: {event.web} {i === 1 ? <span style={{ color: webColor }}>{webDif}</span> : null}</h6>
                      {i === 1 ? <h6 style={{margin: '0 auto'}}><span style={{ color: webColor }}>{webPer}%</span></h6> : null}
                    </div>
                    <div className="comp--card">
                      <h6>Admin: {event.admin} {i === 1 ? <span style={{ color: adminColor }}>{adminDif} </span> : null}</h6>
                      {i === 1 ? <h6 style={{margin: '0 auto'}}><span style={{ color: adminColor }}>{adminPer}%</span></h6> : null}
                    </div>
                  </div>
                  <div className="card">
                    <h3>Total Revenue: £{new Intl.NumberFormat('en-GB').format(event.revenue)} {i === 1 ? <span style={{ color: revColor }}>£{revDif}</span> : null}</h3>
                    {i === 1 ? <h3 style={{margin: '0 auto'}}><span style={{ color: revColor }}>{revPer}%</span></h3> : null}
                  </div>

                  <div className="eventComp__row">
                    <div className="comp--card">
                      <h6>Cancelled: {event.cancelled}</h6>
                    </div>
                    <div className="comp--card">
                      <h6>No Shows: {event.noShows}</h6>
                    </div>
                  </div>

                </section>
              )
            })}
          </section>}
      </section>
    </main>
  )
}